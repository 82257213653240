import 'bootstrap'
import * as $ from 'jquery'
import './../styles/styles.scss'

function runModule(module) {
	// let page = module.default
	// console.log(module)
	// $(document).ready(function () {
	// 	page()
	// })
}

console.log(jsPage)
switch (jsPage) {
	case 'login':
		import(/* webpackChunkName: "login" */ './login').then(runModule)
		break
	case 'dashboard':
		import(/* webpackChunkName: "dashboard" */ './dashboard').then(runModule)
		break
	case 'activationForm':
		import(/* webpackChunkName: "activationForm" */ './activationForm').then(runModule)
		break
	case 'newDevice':
		import(/* webpackChunkName: "activationForm" */ './newDevice').then(runModule)
		break
	case 'addCustomer':
		import(/* webpackChunkName: "addCustomer" */ './addCustomer').then(runModule)
		break
	case 'completionForm':
		import(/* webpackChunkName: "addCustomer" */ './completionForm').then(runModule)
		break
	case 'forgotPassword':
		import(/* webpackChunkName: "forgotPassword" */ './forgotPassword').then(runModule)
		break
	case 'imageUpload':
		import(/* webpackChunkName: "imageUpload" */ './imageUpload').then(runModule)
		break
	default:
		console.log('no js page loaded')
		break
}
